import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const login = createAsyncThunk("user/login", async (email, password) => {
  return axios
    .post("https://api.falconvpn.com/api/auth/login", {
      email,
      password,
    })
    .then((res) => {});
});

export const userSlice = createSlice({
  name: "user",
  initialState: {
    name: "",
    email: "",
    ip: "",
    city: "",
    country: "",
    isp: "",
  },
  reducers: {
    // Reducer comes here
    setUserData: (state, action) => {
      console.log(action.payload, "akcioni payload");
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.ip = action.payload.ip;
      state.city = action.payload.city;
      state.country = action.payload.country;
      state.isp = action.payload.isp;
    },
    resetUserData: (state) => {
      state.name = "";
      state.email = "";
      state.ip = "";
      state.city = "";
      state.country = "";
      state.isp = "";
    },
  },
  extraReducers: {
    // Extra reducer comes here
  },
});
export const { setUserData, resetUserData } = userSlice.actions;
export const userActions = userSlice.actions;
export default userSlice.reducer;
