import React from "react";
import { useSelector } from "react-redux";
import "src/app/components/LocationHeader/index.css";

const LocationHeader = () => {
  // get ip, location, and isp from state
  const user = useSelector((state) => state.user);

  return (
    <div className="header-wrapper">
      <div className="header-container">
        <p>
          <span className="header-key">Your IP:</span>{" "}
          <span className="header-value">{user.ip}</span>
        </p>
        <p className="p-hidden">
          <span className="header-key">Your Location:</span>{" "}
          <span className="header-value">{user.city}, {user.country}</span>
        </p>
        <p className="p-hidden">
          <span className="header-key">Your ISP:</span>{" "}
          <span className="header-value">{user.isp}</span>
        </p>
        <p>
          <span className="header-key">Your Status:</span>{" "}
          <span className="header-value-button">Unprotected</span>
        </p>
      </div>
    </div>
  );
};

export default LocationHeader;
