
import React from "react";
import HeroSection from "src/app/components/HeroSection/variant-2";
import FeatureList from "src/app/components/FeatureList/variant-2";
import Pricing from "src/app/components/Pricing";
import SecurityFeatures from "src/app/components/SecurityFeatures";

const Home = () => {
  return (
    <>
      <HeroSection />
      <FeatureList />
      <Pricing />
      <SecurityFeatures />
    </>
  );
};

export default Home;
