import React, { useState } from "react";
import { useNavigate } from "react-router";
import Button from "react-bootstrap/Button";
import Form from "../../components/Form";
import { requestPasswordReset } from "../../services/auth-service";
import ForgotPasswordComponent from "../../components/ForgotPassword";
import Woman from "src/assets/svg/login_man.svg";
import FlowerSvg from "src/assets/svg/login_cactus.svg";
import Trash from "src/assets/svg/login_trashcan.svg";
import LoginForm from "src/app/components/LoginForm";

const Login = () => {
  return (
    <div className="">
      <p className="page-heading-title login">Welcome back</p>
      <div className="page-container login">
        <div className="page-heading login">
          <img src={Woman} alt="" className="woman" />
          <img src={FlowerSvg} alt="" className="cactus-login" />
          <img src={Trash} alt="" className="trashcan" />
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
