import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../services/auth-service";
import { getUserData } from "src/app/services/user-service";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { userActions } from "./user";
import { Navigate } from "react-router-dom";

const user = JSON.parse(localStorage.getItem("user"));
export const register = createAsyncThunk(
  "auth/register",
  async (newUser, thunkAPI) => {
    try {
      const response = await AuthService.register(
        newUser.name,
        newUser.email,
        newUser.password,
        newUser.password_confirmation
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { dispatch }) => {
    try {
      const data = await AuthService.login(email, password);
      localStorage.setItem("access_token", data.access_token);
      getUserData(data.access_token).then((response) => {
        dispatch(userActions.setUserData(response.data));
      });

      return { user: data };
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      console.log(message, "auth.js Message");
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async ({ dispatch }) => {
  await AuthService.logout(localStorage.access_token).then(() => {
    dispatch(userActions.resetUserData());
  });
});

export const getAccount = createAsyncThunk(
  "auth/account",
  async ({ email, password }, { dispatch }) => {
    try {
      const token = localStorage.getItem("access_token");
      getUserData(token).then((response) => {
        dispatch(userActions.setUserData(response.data));
        console.log(response.data, 'getAccount response.data')
      });

      // return { user: data };
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      console.log(message, "auth.js Message");
    }
  }
);

const initialState = {
  name: "",
  email: "",
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
    },
    resetUserData: (state) => {
      state.name = "";
      state.email = "";
    },
  },
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.name = "";
      state.email = "";
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});
const { reducer, setUserData, resetUserData } = authSlice;
export default reducer;
