import React from "react";
import { Container } from "react-bootstrap";
import Button from "src/app/components/Button";

const Verify = () => {
  const clickHandler = () => {};
  return (
    <Container className="verify">
      <h1>Verify your email address</h1>
      <p>
        Please confirm that you want to use this as your FalconVPN account email
        address. Once it's done you will be able to start using it.
      </p>
      {/* <Button onClick={clickHandler} content="Verify my email" /> */}
    </Container>
  );
};

export default Verify;
