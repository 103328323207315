import React, { useState, useEffect } from "react";
import "src/app/components/RegisterForm/index.css";
import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { register } from "src/app/slices/auth";
import Button from "src/app/components/Button";
import useValidate from "src/app/hooks/useValidate";
import ManSvg from "src/assets/svg/Register/register_man.svg";

const RegisterForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const isLongerThanEightChars = (value) =>
    value.trim().length > 8 && !value.includes(" ");
  const isNotEmpty = (value) => value.trim() !== "";
  let re = /\S+@\S+\.\S+/;
  const isEmail = (value) => re.test(value);
  const isSamePassword = (value) => value === password;

  const {
    value: password,
    isValid: passwordValid,
    hasError: passwordError,
    valueBlurHandler: passwordBlurHandler,
    valueChangedHandler: passwordChangeHandler,
    reset: passwordReset,
  } = useValidate(isLongerThanEightChars);
  const {
    value: name,
    isValid: nameValid,
    hasError: nameError,
    valueBlurHandler: nameBlurHandler,
    valueChangedHandler: nameChangeHandler,
    reset: nameReset,
  } = useValidate(isNotEmpty);
  const {
    value: email,
    isValid: emailValid,
    hasError: emailError,
    valueBlurHandler: emailBlurHandler,
    valueChangedHandler: emailChangeHandler,
    reset: emailReset,
  } = useValidate(isEmail);
  const {
    value: confirmPassword,
    isValid: confirmPasswordValid,
    hasError: confirmPasswordError,
    valueBlurHandler: confirmPasswordBlurHandler,
    valueChangedHandler: confirmPasswordChangeHandler,
    reset: confirmPasswordReset,
  } = useValidate(isSamePassword);

  let formValid = false;
  let passwordConfirmation;

  if (passwordValid && nameValid && emailValid && confirmPasswordValid) {
    formValid = true;
    passwordConfirmation = password;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("naja");

    if (!formValid) {
      alert("Please provide correct information");
      return;
    }
    const newRegistration = {
      name,
      email,
      password,
      passwordConfirmation,
    };

    emailReset();
    nameReset();
    passwordReset();
    confirmPasswordReset();

    dispatch(register(newRegistration)).then((response) => {
      console.log(response, "response");
      if (response.palyoad === undefined) {
        setError("Email is already being used, please provide a new one!");
        // alert("Email is already used, please provide a new one!");
      } else navigate("/verify");
    });
  };

  const clickHandler = () => {
    setError(null);
  };

  return (
    <>
      <p className="page-heading-title">Register your free account</p>
      <p className="page-heading-description">
        We do not share your information and will contact you only as needed to
        provide our service.
      </p>
      <div className="login-wrapper">
        <form onClick={clickHandler} onSubmit={handleSubmit} className="form">
          <p className="form-title">Sign Up</p>
          <input
            className="form-input"
            value={name}
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            type="text"
            placeholder="Name"
          />
          <div className="error-txt register">
            {nameError ? "Name is required." : ""}
          </div>

          <input
            className="form-input"
            value={email}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            type="email"
            placeholder="E-mail"
          />
          {emailError && (
            <span className="error-txt register">Email must be correct</span>
          )}

          <input
            className="form-input"
            type="password"
            placeholder="Password"
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
            value={password}
          />
          {passwordError && (
            <span className="error-txt register">
              Password must be at least 8 characters long.
            </span>
          )}
          <input
            className="form-input"
            value={confirmPassword}
            onChange={confirmPasswordChangeHandler}
            onBlur={confirmPasswordBlurHandler}
            type="password"
            placeholder="Confirm password"
          />
          {confirmPasswordError && (
            <span className="error-txt register">
              Password must be the same
            </span>
          )}
          {error && <span className="error-txt register">{error}</span>}
          <p className="form-redirect">
            Already a member? <a className="form-link">Log in</a>
          </p>
          <Button content="Register" arrow={true} />
        </form>
      </div>
    </>
  );
};

export default RegisterForm;
