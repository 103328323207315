import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Button = (props) => {
  return (
    <button onClick={props.onClick} className={ props.arrow ? `button-arrow ${props.class}` : `button ${props.class}`}>
      {props.content} &nbsp;
      {props.arrow && <FontAwesomeIcon icon="fa-long-arrow-right" />}
    </button>
  );
};

export default Button;
