import React, { useState } from "react";
import { useNavigate } from "react-router";
import Button from "react-bootstrap/Button";
import Form from "../../components/Form";
import { requestPasswordReset } from "../../services/auth-service";
import ForgotPasswordComponent from "../../components/ForgotPassword";
import KeyManSvg from "src/assets/svg/forgot_man.svg";
import FlowerSvg from "src/assets/svg/forgot_cactus.svg";
import LockSvg from "src/assets/svg/forgot_lock.svg";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const emailHandler = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    requestPasswordReset(email);
  };
  return (
    <div className="">
      <p className="page-heading-title forgot">Forgot password?</p>
      <div className="page-container forgot">
        <div className="page-heading forgot">
          <img src={KeyManSvg} alt="" className="humanKey" />
          <img src={FlowerSvg} alt="" className="cactus" />
          <img src={LockSvg} alt="" className="lock" />
          <ForgotPasswordComponent />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
