import React, { useEffect, useState } from "react";
import "src/app/components/LoginForm/index.css";
import { useDispatch } from "react-redux";
import { login } from "src/app/slices/auth";
import { useNavigate } from "react-router-dom";
import Button from "../Button";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { isLoading, isAuth, error } = useSelector((state) => state.login);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [valid, setValid] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(login({ email, password })).then((error) => {
      if (error.payload.user === "Unauthorized") {
        setValid(false);
        return;
      } else {
        navigate("/");
      }
    });
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };
  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className="login-wrapper">
      <div className="form login" onSubmit={handleSubmit}>
        <h3 className="form-title">Sign in</h3>
        <input
          type="text"
          className="form-input"
          placeholder="E-mail"
          onChange={emailHandler}
          value={email}
        />
        {/* {emailError && <span className="error-txt">Wrong email adress</span>} */}
        <input
          type="password"
          className="form-input"
          placeholder="Password"
          type="password"
          onChange={passwordHandler}
          value={password}
        />
        {!valid && (
          <span className="error-txt">
            Please provide correct email and password
          </span>
        )}
        <Button onClick={handleSubmit} content="Sing in" arrow={true} />
      </div>
    </div>
  );
};

export default LoginForm;
