import React from "react";
import Button from "src/app/components/DownloadButton";
import 'src/app/components/HeroSection/variant-2/index.css'

const HeroSection = () => {
  return (
    <div className="hero-2">
      <h1 className="hero-title-2">
        Get your digital privacy back.
        <br />
        <span className="font-blue-2">100% free</span> and <span className="font-blue-2">unlimited.</span>
      </h1>
      <p className="hero-text-2">
        First decentralized VPN operating on a blockchain.
        <br />
        Access all your favorite content privately and securely.
        <br />
        Earn crypto and NFTs{" "}
        <span className="text-secondary">(coming soon)</span>.
      </p>
      <div className="flex-center get-button">
        <Button content="Get FalconVPN" />
      </div>
    </div>
  );
};

export default HeroSection;
