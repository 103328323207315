import axios from "axios";

const API = "https://api.falconvpn.com/api/auth/";

// api functions

export const register = (user) => {
  return axios
    .post(API + "register", {
      name: user.name,
      email: user.email,
      password: user.password,
      password_confirmation: user.passwordConfirmation,
    })
    .then((response) => {
      if (response) {
        return response;
      }
    })
    .catch((error) => {
      console.log(error.response.statusText, "error");
      // console.log(error);
      return error.response.statusText;
    });
};

export const login = (email, password) => {
  return axios
    .post(API + "login", {
      email,
      password,
    })
    .then((response) => {
      console.log(response.status, "RESPONSE");
      if (response.data.access_token) {
        // localStorage.setItem("access_token", JSON.stringify(response.data));
      }
      // else give alert
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response.statusText);
      // console.log(error);
      return error.response.statusText;
    });
};

export const logout = (token) => {
  return axios
    .post(API + "logout", null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log(res);
      localStorage.removeItem("access_token");
    });
};

export const requestPasswordReset = (email) => {
  console.log(email, "email");
  return axios.post(API + "passwordReset/sendEmail", { email: email }, {});
};

export const resetPassword = (values) => {
  console.log(values, "values");
  return axios.post(
    API + "passwordReset",
    {
      email: values.email,
      resetToken: values.token,
      password: values.password,
      password_confirmation: values.password_confirmation,
    },
    {}
  );
};

export const changePassword = (values) => {
  return axios
    .post(
      API + "changePassword",
      {
        password: values.password,
        password_confirmation: values.password_confirmation,
      },
      {
        headers: {
          Authorization: `Bearer ${values.token}`,
        },
      }
    )
    .then((response) => {
      console.log(response.status, "RESPONSE");
      if (response.data.access_token) {
        // localStorage.setItem("access_token", JSON.stringify(response.data));
      }
      // else give alert
      return response.data;
    })
    .catch((error) => {
      console.log(error.response.statusText);
      return error.response.statusText;
    });
};

const authService = {
  register,
  login,
  logout,
  requestPasswordReset,
};
export default authService;
