import React from "react";
import "src/app/components/SecurityFeatures/index.css";
import DownloadButton from "src/app/components/DownloadButton";

const SecurityFeatures = () => {
  return (
    <div className="security-wrapper">
      <p className="security-content-title">Lorem ipsum.</p>
      <div className="security-content">
        <p className="security-content-icon" style={{ fontSize: "1000%" }}>
          🛡
        </p>
        <div className="security-content-text">
          <p className="security-content-text-title">Lorem, ipsum.</p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Asperiores
            ducimus accusantium est facere illo nobis incidunt voluptate tenetur
            culpa possimus!
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quaerat
            voluptate sed dolorum.
          </p>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum ab ea
            itaque voluptatem, et veniam. Explicabo iste dolor sequi fuga sunt
            minima dolorem molestias labore laboriosam. Necessitatibus iusto
            placeat unde non repellendus!
          </p>
        </div>
      </div>
      <p className="security-content-icon-mobile" style={{ fontSize: "1000%" }}>
        🛡
      </p>
      <DownloadButton />
    </div>
  );
};

export default SecurityFeatures;
