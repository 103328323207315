import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { changePassword } from "../services/auth-service";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const token = localStorage.getItem("access_token");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [valid, setValid] = useState(true);
  const navigate = useNavigate();

  const values = {
    token: token,
    password: password,
    password_confirmation: passwordConfirmation,
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };
  const passwordConfirmationHandler = (e) => {
    setPasswordConfirmation(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password || !passwordConfirmation) {
      return alert("Parameters missing!");
    } else {
      // if (password !== passwordConfirmation) {
      //   setValid(false);
      // } else {
      //   changePassword(values);
      // }
      changePassword(values).then((error) => {
        if (error === "Unprocessable Content" || error === "Unauthorized") {
          setValid(false);
        } else {
          navigate("/");
        }
      });
    }
  };

  return (
    <div>
      <Form className="form">
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            value={password}
            onChange={(event) => {
              passwordHandler(event);
            }}
            type="password"
            placeholder="Enter password"
            className="form-item"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPasswordConfirmation">
          <Form.Label>Conffirm Password</Form.Label>
          <Form.Control
            value={passwordConfirmation}
            onChange={(event) => {
              passwordConfirmationHandler(event);
            }}
            type="password"
            placeholder="Confirm password"
            className="form-item"
          />
        </Form.Group>
        {!valid && (
          <span className="error-txt mt-1">Passwords should match</span>
        )}
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
          variant="primary"
          type="submit"
          className="form-item"
        >
          Change Password
        </Button>
      </Form>
    </div>
  );
};

export default ChangePassword;
