import React from 'react'
import Option from 'src/app/components/Pricing/partials/Option.jsx'
import 'src/app/components/Pricing/index.css'

const Pricing = () => {
  const option1 = [
    'Lorem ipsum dolor sit',
    'Lorem ipsum dolor sit',
    'Lorem ipsum dolor sit',
  ]
  return (
    <div className='component-wrapper'>
      <Option content={option1} price="3.33" />
      <Option content={option1} price="3.33" />
      <Option content={option1} price="3.33" />
    </div>
  )
}

export default Pricing
