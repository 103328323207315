import ForgotPasswordSvg from "../ForgotPasswordSvg/Main";
import FlowerSvg from "../ForgotPasswordSvg/Flower";
import Form from "../Form";
import ManSvg from "../ForgotPasswordSvg/Man";
import LockSvg from "../ForgotPasswordSvg/Lock";
import Button from "../Button";

const ForgotPasswordComponent = () => {
  console.log(<ForgotPasswordSvg />);
  return (
    <>
      {/* <p className="page-heading-description">
        We do not share your information and will contact you only as needed to
        provide our service.
      </p> */}
      <div className="login-wrapper">
        <form className="form forgot">
          <input className="form-input" type="email" placeholder="E-mail" />
          <Button content="Register" arrow={true} />
        </form>
      </div>
    </>
  );
};

export default ForgotPasswordComponent;
