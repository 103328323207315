import React from "react";
import 'src/app/components/DownloadButton/index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DownloadButton = (props) => {
  return (
    <button onClick={props.onClick} className="download-button">
      <span>Get FalconVPN</span>
      {/* Get FalconVPN &nbsp; <FontAwesomeIcon icon="fa-long-arrow-right" /> */}
    </button>
  );
};

export default DownloadButton;
