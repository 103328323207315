import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { logout } from "src/app/slices/auth";
import { useSelector, useDispatch } from "react-redux";
import { resetUserData } from "src/app/slices/user";
import Button from "../Button";
import logo from "src/assets/svg/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = () => {
  let auth = localStorage.getItem("access_token");
  let isAuth = auth !== null ? true : false;
  console.log(isAuth);

  useEffect(() => {}, [auth]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout(localStorage.access_token)).then(() => {
      navigate("/login");
    });
  };

  return (
    <Navbar expand="lg" className="main-navbar mt-3">
      <Container className="nav-container">
        <Link to="/" className="nav-logo">
          <img src={logo} alt="FalconVPN" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span>
            <FontAwesomeIcon className="mobile-menu" icon="bars" size="lg" />
          </span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <NavLink className="nav-link" to={"/"}>
              Pricing
            </NavLink>

            <NavLink className="nav-link" to={"/features"}>
              Features
            </NavLink>

            <NavLink className="nav-link" to={"/what"}>
              What is VPN
            </NavLink>

            <Button content="Get FalconVPN" />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
