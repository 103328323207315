import React from "react";
import RegisterForm from "src/app/components/RegisterForm/index.jsx";
import "src/app/views/Register/Register.css";
import ManSvg from "src/assets/svg/Register/register_man.svg";
import FlowerSvg from "src/assets/svg/Register/register_flower.svg";

const Register = () => {
  return (
    <div className="">
      <div className="page-container">
        <div className="page-heading">
          <img src={FlowerSvg} alt="" className="plant"/>
          <img src={ManSvg} alt="" className="human"/>
          <RegisterForm />
        </div>
      </div>
    </div>
  );
};

export default Register;
