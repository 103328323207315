import React from "react";
import "src/app/components/Pricing/partials/Option.css";
import Button from "src/app/components/Button/index.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Option = ({ content, special, price }) => {
  return (
    <div className="option-container">
      <div className="option-heading">
        <FontAwesomeIcon icon="fa-solid fa-splotch" size="3x" />
        <p>Start</p>
        <p>
          <strong style={{ fontSize: '250%' }}>$0&nbsp;</strong>/month
        </p>
        <p>includes</p>
        <div className="option-features">
          <div>✔ 2 Admin seats</div>
          <div>✔ Unlimited Read-Only users</div>
          <div>✔ Unlimited Research Projects</div>
          <div>✔ Unlimited Uploads: Test</div>
          <div>✔ Data Visualizations</div>
          <div>✔ Unlimited Transcriptions</div>
          <div>✔ Video Editing Features</div>
          <div>✔ Taxonomy Manager</div>
        </div>
      </div>
        <Button content="Contact Us" />
    </div>
  );
};

export default Option;
