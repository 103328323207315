import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faWindows } from "@fortawesome/free-brands-svg-icons";
import { faChrome } from "@fortawesome/free-brands-svg-icons";
import { faEdge } from "@fortawesome/free-brands-svg-icons";
import { faFirefox } from "@fortawesome/free-brands-svg-icons";
import { faOpera } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import logo from "src/assets/svg/logo.svg";
const Footer = () => {
  return (
    <Card.Footer className="footer">
      <Container>
        <Row>
          <Col lg={6}>
            <Row>
              <Col md={4}>
                <h4>General</h4>
                <ul>
                  <li>
                    <Link to={"/"}>Service pricing</Link>
                  </li>
                  <li>
                    <Link to={"/"}>TOS</Link>
                  </li>
                  <li>
                    <Link to={"/"}>Available locations</Link>
                  </li>
                  <li>
                    <Link to={"/"}>What's a VPN?</Link>
                  </li>
                  <li>
                    <Link to={"/"}>Security features we provide</Link>
                  </li>
                  <li>
                    <Link to={"/"}>Blog</Link>
                  </li>
                  <li>
                    <Link to={"/"}>FalconVPN Blog</Link>
                  </li>
                  <li>
                    <Link to={"/"}>Contact us</Link>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <h4>Legal Stuff</h4>
                <ul>
                  <li>
                    <Link to={"/"}>Privacy</Link>
                  </li>
                  <li>
                    <Link to={"/"}>Licensing</Link>
                  </li>
                  <li>
                    <Link to={"/"}>Live support</Link>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <h4>Let's get in touch</h4>
                <ul>
                  <li>
                    <Link to={"/"}>info@falconvpn.com</Link>
                  </li>
                  <li>
                    <Link to={"/"}>Contact us</Link>
                  </li>
                  <li>
                    <Link to={"/"}>Found a bug?</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col lg={6}>
            <Row>
              <Col md={6}>
                <h4>Supported devices</h4>
                <ul className="supported-devices">
                  <li>
                    <Link to={"/"} className="icons">
                      <FontAwesomeIcon className="icon-item" icon={faWindows} />
                      <p>
                        Download for <br />
                        <span className="bigger-text"> Windows</span>
                      </p>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={"/"} className="icons">
                      <FontAwesomeIcon className="icon-item" icon={faChrome} />
                      <p>
                        Add to <br />{" "}
                        <span className="bigger-text">Google Chrome</span>
                      </p>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"} className="icons">
                      <FontAwesomeIcon className="icon-item" icon={faEdge} />
                      <p>
                        Add to <br />{" "}
                        <span className="bigger-text">Microsoft Edge</span>
                      </p>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"} className="icons">
                      <FontAwesomeIcon className="icon-item" icon={faFirefox} />
                      <p>
                        Add to <br />{" "}
                        <span className="bigger-text">Firefox</span>
                      </p>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"} className="icons">
                      <FontAwesomeIcon className="icon-item" icon={faOpera} />
                      <p>
                        Add to <br /> <span className="bigger-text">Opera</span>
                      </p>
                    </Link>
                  </li> */}
                  {/* comment */}
                </ul>
              </Col>
              <Col md={6}>
                <h4>Get Updates</h4>
                <ul className="tuned">
                  <li>
                    <Link to={"/"}>
                      {" "}
                      <FontAwesomeIcon className="icon-item" icon={faTwitter} />
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      {" "}
                      <FontAwesomeIcon
                        className="icon-item"
                        icon={faFacebook}
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      {" "}
                      <FontAwesomeIcon
                        className="icon-item"
                        icon={faInstagram}
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      {" "}
                      <FontAwesomeIcon className="icon-item" icon={faYoutube} />
                    </Link>
                  </li>
                </ul>
                {/* <select className="select-language">
                  <option>English</option>
                  <option>Italian</option>
                  <option>French</option>
                </select> */}
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={6} className="logo-footer">
            <img src={logo} alt="FalconVPN" />
          </Col>
          <Col md={6} className="copyright">
            &copy; Copyright 2022 Falcon VPN Ltd. | All rights reserved
          </Col>
        </Row>
      </Container>
    </Card.Footer>
  );
};

export default Footer;
