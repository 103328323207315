import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Feature from "src/app/components/Feature/variant-2";
import shapeA from "src/assets/images/blurred_1.png";
import shapeB from "src/assets/images/blurred_2.png";

const FeatureList = () => {
  return (
    <>
      <div className="features-wrapper">
        {/* <div className="background-images"> */}
        <img className="image-1" src={shapeA} alt="" />
        <img className="image-2" src={shapeB} alt="" />
        {/* </div> */}
        <div className="grid-box">
          <Feature
            icon="shield"
            title="Protect your data"
            content="Whether you are working at home, using public Wi-Fi, or simply browsing the web, keep your data more secure."
          />
          <Feature
            icon="fingerprint"
            title="Prevent tracking"
            content="Your data is not supposed to be a product. FalconVPN prevent trackers from collecting your digital fingerprints."
          />
          <Feature
            icon="ban"
            title="Block annoying ads"
            content="Enjoy your content without interruption. We will block all annoying ads, malware and phishing websites."
          />
          <Feature
            icon="bolt"
            title="Blazing-fast"
            content="You don't have to sacrifice speed for better security. FalconVPN gives you both advantages with up to 1000 Mbps speed."
          />
          <Feature
            icon="desktop"
            title="On every device"
            content="FalconVPN has easy-to-use apps for Windows, Mac, iOS, Android, routers, game consoles, and smart TVs.(Coming soon)"
          />
          <Feature
            icon="wallet"
            title="Earn crypto"
            content="Become a node in our blockchain just by using FalconVPN and get rewards for your service.(Coming soon)"
          />
        </div>
      </div>
    </>
  );
};

export default FeatureList;
