import "src/App.css";
import { Routes, Route, Navigate, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Register from "src/app/views/Register/Register";
import Login from "src/app/views/Login/Login";
import Verify from "src/app/views/Verify/Verify";
import ResetPassword from "src/app/views/ResetPassword";
import ForgotPassword from "src/app/views/ForgotPassword/ForgotPassword";
import ChangePassword from "src/app/views/ChangePassword";
import LocationHeader from "src/app/components/LocationHeader";
import Header from "src/app/components/Header";
import Footer from "src/app/components/Footer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccount } from "src/app/slices/auth";
import { library } from "@fortawesome/fontawesome-svg-core";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import {
  faShield,
  faFingerprint,
  faBolt,
  faArrowRightLong,
  faBars,
  faBan,
  faDesktop,
  faWallet,
  faWindowClose,
  faCheckCircle,
  faSplotch,
} from "@fortawesome/free-solid-svg-icons";
import Home from "./app/views/Home";

library.add(
  faShield,
  faFingerprint,
  faBolt,
  faArrowRightLong,
  faBars,
  faBan,
  faDesktop,
  faWallet,
  faWindowClose,
  faCheckCircle,
  faSplotch,
);

function App() {
  const dispatch = useDispatch();

  const user = useSelector((state) =>
    // state.setUserData.email === "" ? false : true
    console.log("")
  );
  const [authorized, setAuthorized] = useState(
    localStorage.access_token ? true : false
  );

  useEffect(() => {
    if (authorized) {
      const token = localStorage.getItem("access_token");
      dispatch(getAccount(token));
    }
  }, []);

  return (
    <div className="">
      {/* { localStorage.access_token ? <Header /> : null } */}
      <LocationHeader />
      <Header user={user} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset/:token/:email" element={<ResetPassword />} />
        {/* catch all route below */}
        {/* <Route path="*" element={!user ? <Navigate to="/login" /> : <></>} /> */}
      </Routes>
      {/* {localStorage.access_token ? <Footer /> : null} */}
      <Footer />
    </div>
  );
}

export default withLDProvider({
  clientSideID: "620cbfcb802c1f144cf5745c",
  user: {
    key: "user_key",
    name: "User Name",
    email: "user@email.com",
  },
})(App);
