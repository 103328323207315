import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { resetPassword } from "../services/auth-service";

const ResetPassword = () => {
  const params = useParams();
  const [email, setEmail] = useState(params.email);
  const [token, setToken] = useState(params.token);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const values = {
    email: email,
    token: token,
    password: password,
    password_confirmation: passwordConfirmation,
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };
  const passwordConfirmationHandler = (e) => {
    setPasswordConfirmation(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password || !passwordConfirmation) {
      return alert("Parameters missing!");
    } else {
      console.log(values, "else");
      resetPassword(values);
    }
  };

  return (
    <div>
      <Form className="form">
        <h4>Reset Your Password</h4>
        <h6>Please enter your new password.</h6>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          {/* <Form.Label>Email address</Form.Label> */}
          <Form.Control
            value={password}
            onChange={(e) => {
              passwordHandler(e);
            }}
            type="password"
            placeholder="Enter password"
            className="form-item"
          />
          <Form.Text className="text-muted" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPasswordConfirmation">
          {/* <Form.Label>Email address</Form.Label> */}
          <Form.Control
            value={passwordConfirmation}
            onChange={(e) => {
              passwordConfirmationHandler(e);
            }}
            type="password"
            placeholder="Confirm password"
            className="form-item"
          />
          <Form.Text className="text-muted" />
        </Form.Group>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
          variant="primary"
          type="submit"
          className="form-item"
        >
          Reset Password
        </Button>
      </Form>
    </div>
  );
};

export default ResetPassword;
