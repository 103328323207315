import React, { useEffect } from "react";
import '@fortawesome/fontawesome-svg-core/styles.css'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col } from "react-bootstrap";

const Feature = (props) => {
  return (
    <div className="feature-2 w-30">
      <FontAwesomeIcon className={`${props.icon === 'bolt' ? 'feature-icon-bolt' : 'feature-icon-2'}`} icon={props.icon} />
      <h3 className="feature-title-2">{props.title}</h3>
      <p className="feature-body-2">{props.content}</p>
    </div>
  );
};

export default Feature;
