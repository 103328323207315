import axios from "axios";
import authHeader from "./auth-header";
const API = "https://api.falconvpn.com/api/auth/";
export const getUserData = (token) => {
  return axios.get(API + "account", {
    headers: {
      "Authorization": `Bearer ${token}`
    },
  });
};
const userService = {
};
export default userService